<template>
  <div class="details flex-grow-1" v-if="id < 3">
    <h1>Nos conseils pour {{ character }}</h1>

    <ul class="nav nav-tabs" id="tabsAdvice" role="tablist">
      <li class="nav-item">
        <a :class="[ id == 0 ? 'active' : '', 'nav-link']" @click="goToSection(0);" href="#" role="tab" aria-selected="true"><h2>{{ topic[0] }}</h2></a>
      </li>
      <li class="nav-item">
        <a :class="[ id == 1 ? 'active' : '', 'nav-link']" @click="goToSection(1);" href="#" role="tab" aria-selected="false"><h2>{{ topic[1] }}</h2></a>
      </li>
      <li class="nav-item">
        <a :class="[ id == 2 ? 'active' : '', 'nav-link']" @click="goToSection(2);" href="#" role="tab" aria-selected="false"><h2>{{ topic[2] }}</h2></a>
      </li>
    </ul>


    <p class="chapeau"> Vous avez donné {{ categoryScore(questionTotal(topic[id])) }} bon<span v-if="plural==true">s</span> conseil<span v-if="plural==true">s</span> sur {{ questionList.length }} à {{ character }}. Voici comment vous auriez pu mieux l'aider :</p>



    <div
        v-for="question in questionList"
        :key="question"
    >
      <div v-if="question.result == true"
           class="right-answer">
        <p>{{ question.keyword }}</p>
      </div>
      <div v-if="question.result == false"
           class="wrong-answer">
        <p>{{ question.keyword }}</p>
        <p>{{ question.answerExplained }}</p>
      </div>
    </div>
    <div class="my-5 nav-anwser">
      <button @click="formerSection()" class="btn btn-secondary">Précédent</button>
      <button @click="newSection()" class="btn btn-primary ml-2 ml-sm-4 ml-md-5">Suivant</button>
    </div>
  </div>

  <div v-if="id == 3" class="mb-5">
      <h1 class="border-title mb-5">Merci d’avoir participé, mais avant de partir :</h1>
      <router-link to="/Survey" class="btn btn-primary d-block d-sm-inline-block mb-4 mb-sm-0">DONNEZ VOTRE AVIS<br />SUR LE QUIZZ</router-link>
      <a href="/uploads/GUIDE_FOURRAGE_CA.pdf" class="btn btn-secondary d-block d-sm-inline-block ml-sm-4">CONSULTEZ LE GUIDE<br />DES BONNES PRATIQUES</a>
  </div>
</template>

<script>
export default {
  name:     "DetailedAnswer",
  data() {
    return {
      score:        0,
      questionList: [],
      topic:        ['Fauche', 'Pressage', 'Stockage'],
      id:           this.$store.state.idAdvice,
      plural : false,
    }
  },
  computed: {
    quizTotal() {
      return this.$store.state.updatedQuiz;
    },
    character() {
      return this.$store.state.characterChoice;
    },
  },
  methods:  {
    questionTotal(topic) {
      this.questionList = this.quizTotal.filter((question) => question.categorie == topic);
      return this.questionList;
    },
    categoryScore(total) {
      this.score = total.filter((question) => question.result == true).length;
        if(this.score > 1){
          this.plural = true;
        }else{
          this.plural = false;
        };
      return this.score;
    },
    newSection() {
      this.id++
      if (this.id == 3) {
        this.$router.push('Survey')
      }
    },
    formerSection() {
      if (this.id > 0) {
        this.id--;
      } else {
        this.$router.push('Results');
      }
    },
    goToSection(newId) {
        this.id = newId;
        return false;
    }
  }
}
</script>

<style scoped>

.chapeau {
  font-weight: bold;
  font-size: 1.8rem;
  margin-bottom: 2rem;
  color: #006A4E;
}

.wrong-answer,
.right-answer {
  background: #F4F4F4;
  text-align: left;
  font-size: 1.6rem;
  font-weight: bold;
  color: #006A4E;
  border-radius: .5rem;
  overflow: hidden;
  margin-bottom: 1rem;
}

.wrong-answer p,
.right-answer p {
  padding: .5rem 1.5rem 1rem 1.5rem;
  margin-bottom: 0;
}

.wrong-answer p:first-child,
.right-answer p:first-child {
  color: #FFFFFF;
  padding-left: 4rem;
  padding-bottom: .5rem;
  font-size: 1.8rem;
}

.wrong-answer p:first-child {
  background: #006A4E;
  background-image: url("../assets/img/icon-cross@2x.png");
  background-size: 20px auto;
  background-repeat: no-repeat;
  background-position: 1rem center;
}

.right-answer p:first-child {
  background-color: #99C21C;
  background-image: url("../assets/img/icon-check@2x.png");
  background-size: 25px auto;
  background-repeat: no-repeat;
  background-position: 1rem center;
}


.nav-tabs {
  border: 0;
  justify-content: center;
  margin-bottom: 2rem;
}

.nav-tabs .nav-link {
  margin-bottom: 0;
  border: 0;
  border-radius: .25rem;
  color: #006A4E;
 }
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background: #006A4E;
  color: #FFFFFF;
}

.nav-tabs .nav-link h2 {
  margin-bottom: 0;
  font-size: 2rem;
 }

.nav-anwser .btn-secondary {
  background-image: url('../assets/img/icon-arrow-left@2x.png');
  background-repeat: no-repeat;
  background-position: 10% center;
  background-size: auto 14px;
  padding-left: 26px;
  background-color: #1B9787;
 }

.nav-anwser .btn-primary {
  background-image: url('../assets/img/icon-arrow-right@2x.png');
  background-repeat: no-repeat;
  background-position: 82% center;
  background-size: auto 14px;
  padding-right: 26px;
 }

</style>
