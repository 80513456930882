<template>
    <transition name="fade">
      <div class="questionField" :key="index">
        <h3>{{ questionList[index].text }}</h3>
      </div>
    </transition>
</template>

<script>
// 1 - importation tag_commander_wrapper
import TC_Wrapper from "vue-tag-commander";

export default {
    name: 'question',
    props: ['questionList', 'index'],

    data(){
    return{

    }
  },

  mounted() {
      const wrapper = TC_Wrapper.getInstance();

      //debug tag_commander
      //wrapper.setDebug(true);

      wrapper.setTcVars(
        this.questionList[this.index].tagcommander,
      );
  },

  updated() {
      const wrapper = TC_Wrapper.getInstance();

      //debug tag_commander
      //wrapper.setDebug(true);

      wrapper.setTcVars(
        this.questionList[this.index].tagcommander,
      );
  },

  watch() {
    //console.log('helllloooo');
    // ajout variables votre_mission
        const wrapper = TC_Wrapper.getInstance();

        //debug tag_commander
        //wrapper.setDebug(true);


        wrapper.setTcVars(
          this.questionList[this.index].tagcommander
        );
  },
}
</script>

<style scoped>
.questionField{
    color: var(--unnamed-color-006a4e);
    text-align: center;
    color: #006A4E;
    opacity: 1;
}

.fade-enter-active {
  transition: all 1.5s ease;
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}

.fade-leave-active {
  transition: all .3s ease;
  display: none;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

h3{
    font-size:2.2rem;
    font-weight: bold;
}
</style>
