<template>
  <div class="advice">
    <detailed-answer />
  </div>
</template>

<script>
import DetailedAnswer from '../components/DetailedAnswer.vue';
export default {
  name: "Advice",
  components:{
    DetailedAnswer
  }
}
</script>

<style scoped>
.advice {
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
}
</style>
