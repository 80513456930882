<template>
  <div class="survey">
    <h1>Merci d'avoir participé, <br /> avant de partir n'hésitez pas à nous donner votre avis :</h1>

    <form action="/Survey" method="get" class="container" id="formOpinion" v-on:submit.prevent="onSubmit">
      <h2>Sur une échelle de 0 à 10, dans quelle mesure recommanderiez-vous cette expérience à vos proches ?</h2>
      <div class="row mb-5">
        <div v-for="note in noteNumber" :key="note" class="form-check col justify-content-center">
          <input class="form-check-input m-0" type="radio" name="ratingQuiz" :id="'noteXP' + note" :value="note" required>
          <label class="form-check-label d-block" :for="'noteXP' + note">
            {{ note }}
          </label>
        </div>
      </div>
      <h2>Cette expérience vous a-t-elle été utile pour comprendre les enjeux du phénomène d'échauffement des fourrages ?</h2>
      <div class="row mb-5">
        <div v-for="(opinion, index) in opinionList" :key="opinion" class="form-check col justify-content-center">
          <input class="form-check-input" type="radio" name="usefulOrNot" :value="opinion" :id="'useful' + index" required>
          <label class="form-check-label" :for="'useful' + index">
            {{ opinion }}
          </label>
        </div>
      </div>
      <h2>Pensez vous que cette expérience vous aidera à être plus vigilant lors de votre prochaine fenaison? </h2>
      <div class="row mb-5">
        <div v-for="(opinion, index) in opinionList" :key="opinion" class="form-check col justify-content-center">
          <input class="form-check-input" type="radio" :value="opinion" name="moreAlert" :id="'alert' + index" required>
          <label class="form-check-label" :for="'alert' + index">
            {{ opinion }}
          </label>
        </div>
      </div>
      <h2>Quels autres thèmes aimeriez-vous qu'on aborde? Plusieurs choix possibles</h2>
      <div class="row mb-5">
        <div v-for="(topic, index) in topicList" :key="topic" class="form-check col justify-content-center">
          <input class="form-check-input m-0" type="checkbox" :value="topic" name="newTopics" :id="'picked' + index">
          <label class="form-check-label d-block" :for="'picked' + index">
            {{ topic }}
          </label>
        </div>
      </div>
      <div class="my-5">
        <button @click="redirectToAdvice()" class="btn btn-secondary">Précédent</button>
        <button type="submit" class="btn btn-primary ml-2 ml-sm-4 ml-md-5">Valider</button>
      </div>
    </form>
  </div>
</template>

<script>
import $ from 'jQuery';
import firestore from "firebase";

$(function () {

  $.fn.serializeObject = function() {
    var o = {};
    var a = this.serializeArray();
    $.each(a, function() {
      if (o[this.name]) {
        if (!o[this.name].push) {
          o[this.name] = [o[this.name]];
        }
        o[this.name].push(this.value || '');
      } else {
        o[this.name] = this.value || '';
      }
    });
    return o;
  };

  $('#formOpinion').on('submit', function (e) {
    e.preventDefault();
    var formData = $(this).serializeObject();
    sendFirebase(formData);
  });

function sendFirebase(content) {

// Initialize Cloud Firestore through Firebase
firestore.initializeApp({
  apiKey: 'AIzaSyAKT7uoq94X5WzR7jzHVoSc-tWUvBkWJIo',
  authDomain: 'ca-quizz.firebaseapp.com',
  projectId: 'ca-quizz',
  storageBucket: "ca-quizz.appspot.com",
  messagingSenderId: "170806257193",
  appId: "1:170806257193:web:b05b119ebc8f5a401d9e38",
  measurementId: "G-GQBK00ZQQY"
});

firestore.analytics();

var db = firestore.firestore();
let data = JSON.stringify(content, null, 2);
data = JSON.parse(data);
data.date= Date();

db.collection(window.location.host).add(data)

.then(() => {
  $('.survey').addClass('d-flex align-items-center').find('h1').remove();
  $('#formOpinion').html('<h1 class="mb-5">Merci, le questionnaire a été envoyé avec succès !</h1><a href="/uploads/GUIDE_FOURRAGE_CA.pdf" class="btn btn-primary mb-5">CONSULTEZ LE GUIDE<br>DES BONNES PRATIQUES</a>');
})
.catch(() => {
  $('.survey').addClass('d-flex align-items-center').find('h1').remove();
  $('#formOpinion').html('<h1 class="mb-5">Erreur lors de l\'envoi du questionnaire...</h1><a href="/uploads/GUIDE_FOURRAGE_CA.pdf" class="btn btn-primary mb-5">CONSULTEZ LE GUIDE<br>DES BONNES PRATIQUES</a>');
});

  }
});

export default {
  data() {
    return {
      noteNumber:  [0,1,2,3,4,5,6,7,8,9,10],
      opinionList: ["Tout à fait", "Plutôt oui", "Plutôt non", "Pas du tout"].reverse(),
      topicList:   ["Feu de récoltes", "Transformation à la ferme", "Contamination du lait", "Prévention matériel agricole", "Sécurité sur le lieu de travail"]
    }
  },
  methods: {
    redirectToAdvice() {
      /*Renvoie vers la dernière catégorie de DetailedAnswer, à ajuster s'il y a une nouvelle catégorie*/
      this.$store.commit('updatingIdAdvice', 2);
      this.$router.push('Advice');
    }
  }
}

</script>

<style scoped>
.form-check-label {
  font-size: 1.6rem;
}

.survey {
  flex-grow: 1;
}

.survey form {
  color: #006A4E
}

.form-check-input {
  position: static;
  margin: 0 1rem 0 0;
}

.btn-secondary {
  background-image: url('../assets/img/icon-arrow-left@2x.png');
  background-repeat: no-repeat;
  background-position: 10% center;
  background-size: auto 14px;
  padding-left: 26px;
  background-color: #1B9787;
}

.btn-primary {
  background-repeat: no-repeat;
  background-position: 82% center;
  background-size: auto 14px;
  padding-right: 26px;
}
</style>
