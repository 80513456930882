<template>
  <div class="setup mb-4 mt-5">
    <!--<p>Votre nombre de bonnes réponses : {{ totalRightAnswer }}. </p> <br>-->
    <div class="total" v-if="totalRightAnswer >= 10">
      <h2 class="mb-2">Bravo !</h2>
      <hr style="width:5%">
      <p class="text-big">Grâce à vos conseils {{character}} évitera sûrement l’échauffement !</p>
    </div>
    <div class="total" v-else-if="totalRightAnswer >= 5">
      <h2 class="mb-2">Bien !</h2>
      <hr style="width:5%">
      <p class="text-big">Le foin de {{character}} a moins de chance de subir un échauffement ! Vous y êtes presque !</p>
    </div>
    <div class="total" v-else-if="totalRightAnswer <= 4">
      <h2 class="mb-2">Attention !</h2>
      <hr style="width:5%">
      <p class="text-big">Certains points de vigilance méritent votre attention pour mieux conseiller {{character}}.</p>
    </div>
  </div>
  <div class="row mb-4">
    <div class="col-sm mb-4">
      <div class="result-detail" @click="goToAdvice(0)">
        <img v-if="categoryScore(questionTotal('Fauche')) === 0" src="../assets/img/icon-smiley-3@2x.png" alt="">
        <img v-else-if="categoryScore(questionTotal('Fauche')) <= 3" src="../assets/img/icon-smiley-2@2x.png" alt="">
        <img v-else-if="categoryScore(questionTotal('Fauche')) <= 5" src="../assets/img/icon-smiley-1@2x.png" alt="">
        <h3> Fauche</h3>
        <p v-if="categoryScore(questionTotal('Fauche')) === 0 ">Vous n'avez pas donné les bonnes indications pour la fauche, consultez nos conseils.</p>
        <p v-else-if="categoryScore(questionTotal('Fauche')) <= 3">Vous avez permis de réaliser une fauche convenable mais quelques points sont à revoir !</p>
        <p v-else-if="categoryScore(questionTotal('Fauche')) <= 5">Vous avez donné de très bons conseils pour réaliser une bonne fauche.</p>
      </div>
    </div>
    <div class="col-sm mb-4">
      <div class="result-detail" @click="goToAdvice(1)">
        <img v-if="categoryScore(questionTotal('Pressage')) === 0" src="../assets/img/icon-smiley-3@2x.png" alt="">
        <img v-else-if="categoryScore(questionTotal('Pressage')) <= 3" src="../assets/img/icon-smiley-2@2x.png" alt="">
        <img v-else-if="categoryScore(questionTotal('Pressage')) <= 5" src="../assets/img/icon-smiley-1@2x.png" alt="">
        <h3> Pressage</h3>
        <p v-if="categoryScore(questionTotal('Pressage')) === 0 ">Vous n'avez pas donné les bonnes indications pour le pressage, consultez nos conseils.</p>
        <p v-else-if="categoryScore(questionTotal('Pressage')) <= 3">Vous avez permis de réaliser un pressage convenable, mais quelques points sont à revoir !</p>
        <p v-else-if="categoryScore(questionTotal('Pressage')) <= 5">Vous avez donné de très bons conseils pour le pressage ! Félicitations !</p>
      </div>
    </div>
    <div class="col-sm mb-4">
      <div class="result-detail" @click="goToAdvice(2)">
        <img v-if="categoryScore(questionTotal('Stockage')) === 0" src="../assets/img/icon-smiley-3@2x.png" alt="">
        <img v-else-if="categoryScore(questionTotal('Stockage')) <= 3" src="../assets/img/icon-smiley-2@2x.png" alt="">
        <img v-else-if="categoryScore(questionTotal('Stockage')) <= 5" src="../assets/img/icon-smiley-1@2x.png" alt="">
        <h3> Stockage</h3>
        <p v-if="categoryScore(questionTotal('Stockage')) === 0 ">Vous n'avez pas donné les bonnes indications pour gérer les balles suspectes, consultez nos conseils.</p>
        <p v-else-if="categoryScore(questionTotal('Stockage')) <= 3">Vous avez donné de bons conseils pour gérer les balles suspectes mais quelques points sont à revoir !</p>
        <p v-else-if="categoryScore(questionTotal('Stockage')) <= 5">Vous avez donné de très bons conseils pour gérer les balles suspectes.</p>
      </div>
    </div>
  </div>
  <button
    class="btn btn-primary"
    @click="goToAdvice(0)">
     Nos conseils pour {{character}}</button>

</template>

<script>
export default {
  name: "Total",
  created() {
    if (this.$store.state.characterChoice == '') window.location = '/';
  },
  computed: {
    totalRightAnswer () {
        //Va chercher le score dans le store
        //return this.$store.state.rightAnswers;
        return (this.categoryScore(this.questionTotal('Fauche')) + this.categoryScore(this.questionTotal('Pressage')) + this.categoryScore(this.questionTotal('Stockage')));
      },
      quizTotal (){
        //Va chercher le quiz mis à jour dans le store
        return this.$store.state.updatedQuiz;
      },
      character (){
        //Va chercher le personnage choisi dans le store
        return this.$store.state.characterChoice;
      }
  },
  methods:  {
    questionTotal(topic) {
      /*Récupère les questions suivant la catégorie définie par topic */
      return this.quizTotal.filter((question) => question.categorie == topic);
    },
    categoryScore(total) {
      /* Compte les questions déjà dans l'array qui ont la variable */
      return total.filter((question) => question.result == true).length;
    },
    goToAdvice(id) {
      /*Récupère le numéro de la section et l'envoie au store avant de changer de page*/
      this.$store.commit('updatingIdAdvice', id);
        this.$router.push('Advice');
    }
  }
}
</script>

<style scoped>


hr {
  color: white;
  border-top: 3px solid white;
  width: 60px;
}

.total {
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem 5rem 1rem;
}

.total h2 {
  text-transform: uppercase;
  font-weight: bold;
}

.setup {
  border-radius: 15px;
  background: url('../assets/img/herbes-deco@2x.png') no-repeat center bottom #006A4E;
  background-size: 100% auto;
}

.result-detail {
  color: #006A4E;
  background: transparent linear-gradient(180deg, #FFFFFF 0%, #F4F5EF 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #CFDDCDB9;
  border-radius: 10px;
  padding: 1rem .5rem;
}

.result-detail img {
  width: 42px;
  margin-bottom: 1rem;
}

.result-detail h3 {
  margin: 0 0 1rem 0;
  font-weight: bold;
  text-transform: uppercase;
}

</style>
