<template>
  <div class="results pb-5">
    <total />
  </div>
</template>

<script>
import total from "@/components/Total.vue";

export default {
  name: "Results",
  components:{
    total
  }
}
</script>

<style scoped>

.results {
  color: #006A4E;
  max-width: 700px;
  margin: 0 auto;
  flex-grow: 1;
}

</style>
