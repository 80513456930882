<template>
  <div class="mentions">
    <h1>Mentions légales Dispositif « Parlons fourrage » </h1>
    <div class="text-left mb-5">
      <p>Ce site est &eacute;dit&eacute; par PACIFICA, filiale d&rsquo;assurances dommages de Cr&eacute;dit&nbsp;Agricole Assurances. PACIFICA, S.A. au capital enti&egrave;rement lib&eacute;r&eacute; de 442 524 390 euros.</p>

      <p>Le si&egrave;ge social de PACIFICA est situ&eacute; 8-10 Boulevard de Vaugirard &ndash; 75724 Paris Cedex 15.</p>

      <p>PACIFICA est immatricul&eacute;e au R.C.S de Paris sous le num&eacute;ro&nbsp;352&nbsp;358&nbsp;865. Son num&eacute;ro d&rsquo;identification d&rsquo;assujetti &agrave; la TVA est le FR95&nbsp;352&nbsp;358&nbsp;865.</p>

      <p>PACIFICA est une entreprise r&eacute;gie par le Code des Assurances et agr&eacute;&eacute;e par l&rsquo;Autorit&eacute; de Contr&ocirc;le Prudentiel et de R&eacute;solution (ACPR, 4 Place de Budapest CS92459, 75436 Paris Cedex 09).</p>

      <p>Directeur de Publication et repr&eacute;sentant l&eacute;gal&nbsp;: Thierry LANGRENEY, Directeur G&eacute;n&eacute;ral de PACIFICA.</p>

      <p>Directeur de la r&eacute;daction&nbsp;: Caroline NICAISE, Directrice de la Communication, de l&rsquo;Innovation et de la RSE de Cr&eacute;dit Agricole Assurances.</p>

      <p>Contact&nbsp;:&nbsp;<a href="mailto:lesassurancesducreditagricole@ca-pacifica.fr">lesassurancesducreditagricole@ca-pacifica.fr</a></p>

      <p>Num&eacute;ro de t&eacute;l&eacute;phone : 01 53 74 33 00</p>

      <p><strong>Mise en garde et responsabilit&eacute;</strong></p>

      <p>En acc&eacute;dant au pr&eacute;sent site Internet et aux informations qu&rsquo;il contient, l&rsquo;internaute, ci-apr&egrave;s d&eacute;sign&eacute; &laquo;&nbsp;Utilisateur&nbsp;&raquo; reconnait avoir pris connaissance des pr&eacute;sentes conditions d&rsquo;utilisation du Site et s&rsquo;engage &agrave; les respecter.</p>

      <p>Ces dispositions ne le d&eacute;chargent pas des obligations qu&rsquo;il contracte en acc&eacute;dant par un lien &agrave; une page ou un site Internet tiers.</p>

      <p>Le Site Web est r&eacute;serv&eacute; &agrave; l&#39;usage priv&eacute; de chaque participant au test &laquo; Parlons Fourrage &raquo;.</p>

      <p>Chaque participant au dispositif &laquo; Parlons Fourrage &raquo; est seul responsable de l&#39;usage qu&#39;il fait des informations fournies &agrave; partir du Site et PACIFICA ne pourra en aucun cas &ecirc;tre tenu responsable de tous dommages directs ou indirects d&eacute;coulant de l&#39;utilisation de ces informations par le participant. PACIFICA ne garantit pas que le Site sera accessible de mani&egrave;re continue. Les sites de tiers, leur contenu et/ou les documents accessibles &agrave; partir des liens hypertextes publi&eacute;s sur le Site ou dans les documents t&eacute;l&eacute;chargeables &agrave; partir de ce dernier sont la responsabilit&eacute; exclusive de leur(s) auteur(s) et ne sauraient engager la responsabilit&eacute; de PACIFICA. Les participants ne sont pas autoris&eacute;s &agrave; cr&eacute;er un lien hypertexte vers le Site et/ou une page quelconque du Site et/ou d&#39;un fichier s&#39;y trouvant sans l&#39;accord pr&eacute;alable, &eacute;crit et expr&egrave;s de PACIFICA.</p>

      <p><strong>Protection des donn&eacute;es personnelles</strong></p>

      <p>Consultez la Politique de protection des donn&eacute;es.</p>

      <p><strong>Propri&eacute;t&eacute;&nbsp;intellectuelle</strong></p>

      <p>Le Site doit &ecirc;tre consid&eacute;r&eacute; comme un tout indissociable. Ainsi, le site, sa structure g&eacute;n&eacute;rale, et chacun des &eacute;l&eacute;ments qui le compose, et notamment les textes, articles, lettres d&#39;informations, communiqu&eacute;s, pr&eacute;sentations, brochures, illustrations, dessins, graphismes, photographies, programmes informatiques, animations, etc., sont la propri&eacute;t&eacute; intellectuelle exclusive de PACIFICA.</p>

      <p>L&rsquo;ensemble de ce Site rel&egrave;ve de la l&eacute;gislation fran&ccedil;aise sur les droits d&rsquo;auteur et de la propri&eacute;t&eacute; intellectuelle.</p>

      <p>Les noms, marques et enseignes cit&eacute;s sur ce site sont la propri&eacute;t&eacute; de leurs d&eacute;posants respectifs. Toute utilisation ou reproduction, totale ou partielle, du site, des &eacute;l&eacute;ments qui le composent et/ou des informations qui y figurent, par quelque proc&eacute;d&eacute; que ce soit, constitue une contrefa&ccedil;on sanctionn&eacute;e par le Code de la propri&eacute;t&eacute; intellectuelle.</p>

      <p>Les participants au jeu &laquo; Parlons Fourrage &raquo; ne sont autoris&eacute;s qu&#39;&agrave; repr&eacute;senter le Site sur l&#39;&eacute;cran de leur ordinateur personnel, imprimer les pages du Site pour leur usage personnel et reproduire temporairement les fichiers qui le constituent dans la m&eacute;moire cache de cet ordinateur aux seules fins de faciliter la consultation du Site.</p>

      <p>En particulier et sous r&eacute;serve des dispositions de l&#39;alin&eacute;a pr&eacute;c&eacute;dent, le participant au jeu &laquo; Parlons Fourrage &raquo; n&#39;est pas autoris&eacute; &agrave; reproduire, repr&eacute;senter, modifier, traduire et/ou adapter, partiellement ou totalement, &agrave; titre on&eacute;reux ou gratuit, le Site et chacun des &eacute;l&eacute;ments qui le compose, et reproduire et/ou repr&eacute;senter de telles traductions, adaptations et modifications, partiellement ou totalement, &agrave; titre on&eacute;reux ou gratuit, sans l&#39;accord pr&eacute;alable &eacute;crit de PACIFICA.</p>

      <p>Par exception aux alin&eacute;as pr&eacute;c&eacute;dents, PACIFICA autorise les participants au Jeu &laquo; Parlons Fourrage &raquo; &agrave; t&eacute;l&eacute;charger sur le disque dur de leur ordinateur personnel les &eacute;ventuelles lettres d&#39;informations ou les autres documents qui sont mis &agrave; la disposition des participants sous la forme de fichiers &eacute;lectroniques sp&eacute;cifiquement en vue de leur t&eacute;l&eacute;chargement. Ces fichiers ne peuvent &ecirc;tre t&eacute;l&eacute;charg&eacute;s qu&#39;&agrave; partir de liens hypertextes reproduisant la mention &quot;t&eacute;l&eacute;charger ce document ici&quot; ou toute autre mention manifestant l&#39;autorisation donn&eacute;e aux Utilisateurs de t&eacute;l&eacute;charger ces fichiers.</p>

      <p>Dans ce cas, et sauf disposition contraire expresse reproduite sur chacun des documents susvis&eacute;s, le participant au jeu &laquo; Parlons Fourrage &raquo; ne sera autoris&eacute; qu&#39;&agrave; d&eacute;tenir une copie de ces documents et qu&#39;&agrave; imprimer ces documents pour un usage strictement priv&eacute;. Il s&#39;interdit en particulier de diffuser &agrave; des tiers ces documents, y compris par envoi par courrier &eacute;lectronique, par impression et distribution sur un support tangible et par mise &agrave; disposition sur un serveur install&eacute; sur un r&eacute;seau public ou priv&eacute;.</p>

      <p><strong>Entreprises ou&nbsp;organismes cit&eacute;s</strong></p>

      <p>Les informations relatives aux organismes, aux entreprises et aux personnes sur le site jeu &laquo; Parlons Fourrage &raquo; sont constitu&eacute;es et mises &agrave; jour sur un mode d&eacute;claratif et n&#39;engagent en rien la responsabilit&eacute; de l&#39;&eacute;diteur. L&#39;utilisation des informations relatives aux entreprises pour des objectifs commerciaux (offres de services, etc.) est interdite.</p>

      <p>Leur utilisation massive et r&eacute;currente pour un objectif autre que commercial doit au pr&eacute;alable faire l&#39;objet d&#39;une demande d&#39;autorisation.</p>

      <p><strong>Clause attributive de comp&eacute;tence</strong></p>

      <p>Ce site est soumis &agrave; la loi fran&ccedil;aise. En cas de litige, les tribunaux fran&ccedil;ais seront seuls comp&eacute;tents.</p>

    </div>


  </div>
</template>

<script>
// import TC_Wrapper from 'vue-tag-commander';
// const wrapper = TC_Wrapper.getInstance()
// wrapper.setTcVars({
//   environnement : 'dev',
//   page_chapter1 : '',
//   page_chapter2 : '',
//   page_chapter3 : '',
//   page : 'mentions_legales'
// });
</script>

<style scoped>

.mentions {
  max-width: 700px;
  margin: 0 auto;
}

.mentions a {
  color: #e00817;
}

</style>
