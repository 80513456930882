<template>
  <div class="support">
    <h1>Politique des cookies</h1>
    <div class="text-left mb-5">

      <p>Lors de la consultation de notre site internet, des informations relatives &agrave; votre navigation sont susceptibles d&#39;&ecirc;tre enregistr&eacute;es dans des fichiers &quot;Cookies&quot; install&eacute;s sur votre terminal (ordinateur, tablette, smartphone&hellip;), sous r&eacute;serve de vos choix exprim&eacute;s concernant les cookies et que vous pouvez modifier &agrave; tout moment.<br />
        Les informations ci-dessous vous permettent de mieux comprendre le fonctionnement des cookies et l&#39;utilisation des outils actuels afin de les param&eacute;trer.</p>

      <h2>QU&#39;EST-CE QU&#39;UN COOKIE ?</h2>

      <p>Un cookie est un petit fichier texte, image ou logiciel, contenant des informations qui est enregistr&eacute; sur le disque dur de votre terminal (ex&nbsp;: ordinateur, tablette, smartphone, ou tout autre appareil permettant de naviguer sur internet) &agrave; l&rsquo;occasion de la consultation d&rsquo;un site internet. Il est transmis par le serveur d&rsquo;un site Internet &agrave; votre navigateur. Le fichier cookie porte notamment sur les pages visit&eacute;es, les publicit&eacute;s sur lesquelles vous avez cliqu&eacute;es, le type de navigateur que vous utilisez, votre adresse IP, et permet &agrave; son &eacute;metteur d&rsquo;identifier le terminal dans lequel il est enregistr&eacute;, pendant la dur&eacute;e de validit&eacute; du cookie concern&eacute;.</p>

      <p>Comme indiqu&eacute; sur le bandeau d&rsquo;information du site, seuls les cookies accept&eacute;s lors de votre premi&egrave;re navigation sur le site sont susceptibles d&rsquo;&ecirc;tre d&eacute;pos&eacute;s sur votre terminal. Vous pouvez &agrave; tout moment revenir sur cette d&eacute;cision en vous opposant au d&eacute;p&ocirc;t des cookies (voir ci-dessous&nbsp;: <em>comment g&eacute;rer les cookies</em>).</p>

      <h2>A QUOI SERT UN COOKIE ?</h2>

      <p>Les cookies ont plusieurs fonctions.</p>

      <p>Ils facilitent notamment votre navigation sur notre site internet et am&eacute;liorent consid&eacute;rablement sa convivialit&eacute;. Les cookies, sous r&eacute;serve de vos choix exprim&eacute;s concernant les cookies et que vous pouvez modifier &agrave; tout moment, peuvent v&eacute;rifier si votre terminal de navigation (ordinateur, tablette, smartphone&hellip;) a d&eacute;j&agrave; &eacute;t&eacute; connect&eacute; &agrave; notre site et de conserver certaines informations de votre passage. Ces informations permettront d&rsquo;am&eacute;liorer votre navigation lors de la visite suivante.</p>

      <p>Certains cookies techniques sont indispensables pour le bon fonctionnement du site internet. Leur suppression peut entra&icirc;ner des difficult&eacute;s de navigation ainsi que l&rsquo;impossibilit&eacute; d&rsquo;utiliser certains services propos&eacute;s par notre site Internet.</p>

      <p>QUELS SONT LES COOKIES UTILIS&Eacute;S SUR NOTRE SITE ?</p>

      <p>Notre site Internet utilise diff&eacute;rentes cat&eacute;gories de cookies. Certains cookies techniques (cookies de session notamment) sont strictement n&eacute;cessaires au bon fonctionnement de notre site internet. Leur suppression peut entra&icirc;ner des difficult&eacute;s de navigation. Les cookies sont conserv&eacute;s pendant une dur&eacute;e n&rsquo;exc&eacute;dant pas 13 mois, sauf suppression pr&eacute;alable de votre part (voir ci-dessous&nbsp;: <em>comment g&eacute;rer les cookies&nbsp;?</em>).</p>

      <h2>LES COOKIES TECHNIQUES ET FONCTIONNELS</h2>

      <p>Ces cookies permettent d&rsquo;acc&eacute;der, gr&acirc;ce &agrave; vos identifiants personnels (cookie identifiant de session), &agrave; certains espaces r&eacute;serv&eacute;s, de mettre en &oelig;uvre des mesures de s&eacute;curit&eacute; (lorsqu&rsquo;il vous est par exemple demand&eacute; de vous identifier &agrave; nouveau apr&egrave;s un certain d&eacute;lai d&rsquo;inactivit&eacute;), de permettre ou de faciliter la communication en vue de la fourniture des services propos&eacute;s par notre site (acc&egrave;s &agrave; vos espaces personnels notamment).&nbsp;<br />
        <br />
        Ces cookies sont indispensables et strictement n&eacute;cessaires pour vous permettre de naviguer sur notre site et d&rsquo;utiliser ses fonctionnalit&eacute;s. Ils nous permettent &eacute;galement d&rsquo;adapter la pr&eacute;sentation de notre site aux pr&eacute;f&eacute;rences d&rsquo;affichage de votre terminal.&nbsp;<br />
        <br />
        Ces cookies peuvent &ecirc;tre param&eacute;tr&eacute;s au niveau du navigateur. N&eacute;anmoins, si vous choisissez de d&eacute;sactiver les cookies au niveau du navigateur, notre site peut fonctionner diff&eacute;remment.&nbsp;<br />
        <br />
        Certains cookies sont &eacute;galement n&eacute;cessaires &agrave; l&rsquo;enregistrement et au respect de pr&eacute;f&eacute;rences et de vos options notamment concernant le refus d&rsquo;ajouter d&rsquo;autres types de cookies sur votre appareil. Si vous supprimez les cookies au niveau du navigateur, celui utilis&eacute; pour reconna&icirc;tre vos options sera &eacute;galement supprim&eacute;, vous obligeant &agrave; s&eacute;lectionner de nouveau vos pr&eacute;f&eacute;rences.</p>

      <p>Ces cookies sont &eacute;mis et d&eacute;pos&eacute;s par PACIFICA. Ils ont une dur&eacute;e de vie tr&egrave;s courte, pour la majorit&eacute;, le temps de la cession, et au maximum une ann&eacute;e.</p>

      <h2>LES COOKIES DE PERFORMANCE ET DE MESURE D&rsquo;AUDIENCE</h2>

      <p>Ces cookies recueillent des informations sur la fa&ccedil;on dont les visiteurs utilisent un site internet (nombre de visites, les pages les plus visit&eacute;es, les contenus les plus consult&eacute;s, etc.). Ces cookies permettent d&rsquo;&eacute;tablir des statistiques d&rsquo;analyse de la fr&eacute;quentation&nbsp; notre site internet et d&rsquo;en am&eacute;liorer les fonctionnalit&eacute;s.</p>

      <p>XITI (AT Internet), l&#39;outil de statistiques utilis&eacute; par PACIFICA, g&eacute;n&egrave;re un cookie avec un identifiant unique, dont la dur&eacute;e de conservation est limit&eacute;e &agrave; 13 mois. Les donn&eacute;es recueillies ne sont pas c&eacute;d&eacute;es &agrave; des tiers ni utilis&eacute;es &agrave; d&rsquo;autres fins.</p>

      <h2>COMMENT G&Eacute;RER LES COOKIES ?</h2>

      <p>Plusieurs possibilit&eacute;s vous sont offertes pour g&eacute;rer les cookies. Vous pouvez faire le choix &agrave; tout moment d&rsquo;exprimer et de modifier vos souhaits en mati&egrave;re de cookies, par les moyens d&eacute;crits ci-dessous.</p>

      <p style="text-decoration: underline;"><strong>1. Param&eacute;trage de votre navigateur</strong></p>

      <p>Vous avez la possibilit&eacute; de naviguer sur notre site et de vous opposer &agrave; l&rsquo;utilisation des cookies. Pour cela, vous devez s&eacute;lectionner une option sur votre navigateur telle que &laquo; bloquer/interdire les cookies &raquo;. Leur suppression peut entra&icirc;ner des difficult&eacute;s voire une impossibilit&eacute; de navigation ainsi que l&rsquo;impossibilit&eacute; de souscrire en ligne &agrave; nos offres.</p>

      <p>Vous avez &eacute;galement la possibilit&eacute; de supprimer &agrave; tout moment les cookies d&eacute;j&agrave; pr&eacute;sents dans votre terminal. N&eacute;anmoins, tout param&eacute;trage que vous pouvez entreprendre sera susceptible de modifier votre navigation sur Internet et vos conditions d&rsquo;acc&egrave;s &agrave; certains services n&eacute;cessitant l&rsquo;utilisation de Cookies.</p>

      <p>Vous pouvez configurer votre logiciel de navigation de mani&egrave;re &agrave; ce que des cookies soient enregistr&eacute;s dans votre terminal ou au contraire qu&rsquo;ils soient rejet&eacute;s soit syst&eacute;matiquement, soit&nbsp;selon leur &eacute;metteur. Vous pouvez &eacute;galement configurer votre logiciel de navigation de mani&egrave;re &agrave; ce que l&rsquo;acceptation ou le refus des cookies vous soient propos&eacute;s ponctuellement, avant qu&rsquo;un cookie soit susceptible d&rsquo;&ecirc;tre enregistr&eacute; dans votre terminal.</p>

      <p>La configuration de chaque navigateur est diff&eacute;rente. Elle est d&eacute;crite dans le menu d&rsquo;Aide de votre navigateur.</p>

      <p>Pour Chrome</p>

      <p><a href="https://support.google.com/chrome/answer/95647?hl=fr&amp;hlrm=en" target="_blank">support.google.com/chrome/answer/95647?hl=fr&amp;hlrm=en</a></p>

      <p>Pour Internet Explorer</p>

      <p><a href="https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank">support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies</a></p>

      <p>Pour Firefox</p>

      <p><a href="https://support.mozilla.org/fr/kb/cookies-informations-sites-enregistrent" target="_blank">support.mozilla.org/fr/kb/cookies-informations-sites-enregistrent</a></p>

      <p>Pour Safari</p>

      <p><a href="https://support.apple.com/fr-fr/guide/safari/sfri11471/mac" target="_blank">support.apple.com/fr-fr/guide/safari/sfri11471/mac</a></p>

      <p>Pour Opera</p>

      <p><a href="https://help.opera.com/en/latest/web-preferences/#cookies" target="_blank">help.opera.com/en/latest/web-preferences/#cookies</a></p>

      <p style="text-decoration: underline;"><strong>2. Vos choix exprim&eacute;s en ligne directement depuis notre site&nbsp;: les modalit&eacute;s d&rsquo;opt-out</strong></p>

      <p>Vous pouvez &eacute;galement param&eacute;trer depuis notre site vos choix relatifs aux cookies que nous d&eacute;posons sur votre terminal, en utilisant cliquant sur le bouton ci-dessous. En cas de refus du cookie, il sera enregistr&eacute; dans votre terminal un cookie ayant pour unique objet de d&eacute;sactiver les services associ&eacute;s au cookie.</p>

      <p>Attention, la prise en compte de votre souhait repose sur un cookie. Si vous supprimez tous les cookies enregistr&eacute;s dans votre terminal, via votre navigateur, nous ne saurons plus que vous avez choisi cette option.</p>

      <p>&nbsp;</p>


    </div>
  </div>
</template>

<script>
// import TC_Wrapper from "vue-tag-commander";

// const wrapper = TC_Wrapper.getInstance()
// wrapper.setTcVars({
//   environnement : 'dev',
//   page_chapter1 : '',
//   page_chapter2 : '',
//   page_chapter3 : '',
//   page : 'politique_des_cookies'
// });
</script>

<style scoped>
.support {
  max-width: 700px;
  margin: 0 auto;
}

</style>
