<template>
  <div class="pushInfo mb-4">
    {{answerInfo}}
    </div>
   <button v-if="!redirect"
      class="btn btn-primary"
      @click='nextQuestion()'
    >
      Je continue
    </button>
   <button v-if="redirect"
      class="btn btn-primary"
      @click="$router.push('Results')"
    >
     Voir mes résultats
     </button>


</template>

<script>
export default {
  name: "explanation",
  props: ['answerInfo', 'redirect'],
    methods: {
      /* Au click sur le bouton, on lance la méthode answerClicked qui déclenche l'événement "buttonClicked" : y rajouter le tag vrai/faux ensuite en argument */
      nextQuestion() {
          this.$emit('nextQuestion');
      }
  }
}
</script>

<style scoped>
.pushInfo{
  text-align: left;
  color: #006A4E;
  font-size: 1.8rem;
  font-weight: bold;
  white-space: pre-line;
}
</style>
