<template>
  <div class="support">
    <h1>Politique de protection des données personnelles</h1>
    <div class="text-left mb-5">
      <p>Cette politique de protection des donn&eacute;es &agrave; caract&egrave;re personnel fixe la mani&egrave;re dont les donn&eacute;es des Utilisateurs sont trait&eacute;es dans le cadre de ce site.</p>

      <p>Les donn&eacute;es collect&eacute;es pourront faire l&#39;objet de traitement, automatis&eacute; ou non, conform&eacute;ment au r&egrave;glement g&eacute;n&eacute;ral sur la protection des donn&eacute;es &laquo; RGPD &raquo; du 27 avril 2016 et dans les conditions attach&eacute;es &agrave; chaque support de collecte ou service, d&eacute;crites dans la pr&eacute;sente politique de protection des donn&eacute;es &agrave; caract&egrave;re personnel.</p>

      <p>Conform&eacute;ment &agrave; la r&egrave;glementation relative &agrave; la protection des donn&eacute;es &agrave; caract&egrave;re personnel, les Participants au Jeu sont inform&eacute;s que les donn&eacute;es &agrave; caract&egrave;re personnel les concernant sont collect&eacute;es par PACIFICA, en sa qualit&eacute; de responsable de traitement et sont obligatoires pour participer au Jeu et pr&eacute;tendre &agrave; la remise du Lot, s&rsquo;il y a lieu. Le d&eacute;faut de communication de ces donn&eacute;es aura pour cons&eacute;quence l&rsquo;impossibilit&eacute; pour PACIFICA de traiter votre demande de participation.</p>

      <p>Ces donn&eacute;es seront n&eacute;cessaires afin de permettre &agrave; l&rsquo;Utilisateur de participer au jeu et de permettre l&rsquo;envoi des lots aux gagnants, le cas &eacute;ch&eacute;ant. Leur collecte repose sur l&rsquo;int&eacute;r&ecirc;t l&eacute;gitime de PACIFICA.</p>

      <p>Les donn&eacute;es sont conserv&eacute;es pendant la dur&eacute;e du jeu.</p>

      <p>&nbsp;</p>

      <p><strong>Modalit&eacute;s d&rsquo;exercice des droits par les Utilisateurs</strong></p>

      <p>Conform&eacute;ment &agrave; la r&eacute;glementation relative &agrave; la protection des donn&eacute;es &agrave; caract&egrave;re personnel en vigueur, l&rsquo;Utilisateur dispose :</p>

      <ul>
        <li>&nbsp;&nbsp;&nbsp; D&rsquo;un droit d&rsquo;acc&egrave;s, de rectification et d&rsquo;effacement de ses donn&eacute;es,</li>
        <li>&nbsp;&nbsp;&nbsp; D&rsquo;un droit d&rsquo;opposition au traitement de ses donn&eacute;es pour des raisons tenant &agrave; sa situation particuli&egrave;re,</li>
        <li>&nbsp;&nbsp;&nbsp; Du droit de retirer son consentement &agrave; tout moment lorsque celui-ci est requis,</li>
        <li>&nbsp;&nbsp;&nbsp; Du droit &agrave; la limitation du traitement de ses donn&eacute;es</li>
        <li>&nbsp;&nbsp;&nbsp; Du droit &agrave; la portabilit&eacute; de ses donn&eacute;es,</li>
        <li>&nbsp;&nbsp;&nbsp; Du droit de communiquer sur le sort de ses donn&eacute;es en cas de d&eacute;c&egrave;s.</li>
      </ul>

      <p>Ces droits peuvent &ecirc;tre exerc&eacute;s, en justifiant de son identit&eacute;, sur simple demande &eacute;crite adress&eacute;e par courrier &agrave; l&rsquo;adresse suivante :</p>

      <p><em>PACIFICA<br />
        D&eacute;l&eacute;gu&eacute; &agrave; la Protection des Donn&eacute;es<br />
        8/10 boulevard de Vaugirard<br />
        75724 Paris Cedex 15</em></p>

      <p>Ou par courrier &eacute;lectronique &agrave; l&rsquo;adresse suivante : donnees.personnelles@ca-pacifica.fr</p>

      <p>En cas de demande formul&eacute;e par courrier postal, les frais de timbre seront rembours&eacute;s sur simple demande de l&rsquo;Utilisateur.</p>

      <p>En cas de contestation, les Utilisateurs peuvent former une r&eacute;clamation aupr&egrave;s de la CNIL dont le site internet est accessible &agrave; l&rsquo;adresse suivante http://www.cnil.fr et le si&egrave;ge social est situ&eacute; 3 Place de Fontenoy, 75007 Paris.</p>

      <p>&nbsp;</p>

      <p><strong>Destinataires des donn&eacute;es</strong></p>

      <p>Pour les besoins du pr&eacute;sent traitement, les donn&eacute;es collect&eacute;es dans le cadre du jeu sont destin&eacute;es &agrave; PACIFICA. La liste des destinataires pourra vous &ecirc;tre communiqu&eacute;e sur simple demande de votre part en &eacute;crivant par lettre simple au DPO dont l&rsquo;adresse figure ci-dessus.</p>

      <p>&nbsp;</p>

      <p><strong>S&eacute;curit&eacute;</strong></p>

      <p>La pr&eacute;occupation de PACIFICA est de pr&eacute;server la qualit&eacute;, la confidentialit&eacute; et l&rsquo;int&eacute;grit&eacute; des donn&eacute;es &agrave; caract&egrave;re personnel des Utilisateurs. Les technologies et les politiques de s&eacute;curit&eacute; appliqu&eacute;es permettent de prot&eacute;ger ces donn&eacute;es contre tout acc&egrave;s non autoris&eacute;, toute utilisation impropre. Les diff&eacute;rentes personnes qui ont acc&egrave;s aux donn&eacute;es &agrave; caract&egrave;re personnel des Utilisateurs sont tenues au respect de leur vie priv&eacute;e et &agrave; la confidentialit&eacute; de leurs donn&eacute;es personnelles.</p>

      <p>PACIFICA ne demandera jamais aux Utilisateurs de se connecter &agrave; leur banque en ligne ou de fournir des donn&eacute;es &agrave; caract&egrave;re confidentielle (num&eacute;ro de carte bancaire, code...).</p>

      <p>L&rsquo;Utilisateur reconna&icirc;t qu&rsquo;il est impossible de garantir une s&eacute;curit&eacute; totale aux donn&eacute;es transmises sur le r&eacute;seau internet et qu&rsquo;il lui appartient &eacute;galement de mettre en &oelig;uvre tous moyens utiles aux fins de pr&eacute;server la confidentialit&eacute; de ses donn&eacute;es &agrave; caract&egrave;re personnel.</p>

      <p>En cas de demande d&rsquo;information suspecte, il est recommand&eacute; de ne saisir aucune information et d&rsquo;en avertir PACIFICA &agrave; l&rsquo;adresse suivante : avis-clients@ca-pacifica.fr</p>

    </div>
  </div>
</template>

<script>
// import TC_Wrapper from "vue-tag-commander";

// const wrapper = TC_Wrapper.getInstance()
// wrapper.setTcVars({
//   environnement : 'dev',
//   page_chapter1 : '',
//   page_chapter2 : '',
//   page_chapter3 : '',
//   page : 'protection_des_donnees_personnelles'
// });
</script>

<style scoped>
.support {
  max-width: 700px;
  margin: 0 auto;
}

</style>
